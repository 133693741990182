<template>
  <div>
    <!-- Header Alert -->
    <b-alert
      show
      variant="light"
      class="alert alert-custom alert-white alert-shadow fade show gutter-b"
    >
      <div class="alert-icon">
        <span class="svg-icon svg-icon-lg">
          <inline-svg src="/media/svg/icons/Tools/Compass.svg" />
        </span>
      </div>
      <div class="alert-text">Daftar <b>Satuan</b></div>
      <b-button
        squared
        variant="primary"
        @click="$router.push('/masters/uom/add')"
        v-if="btnAccess"
        >Tambah</b-button
      >
    </b-alert>

    <div class="row">
      <div class="col-md-12">
        <div class="card">
          <div class="card-body">
            <div>
              <!-- Filter -->
              <div class="row justify-content-end mt-n3">
                <div class="col-md-4">
                  <b-input-group>
                    <template #append>
                      <b-button
                        variant="success"
                        squared
                        size="sm"
                        @click="search"
                      >
                        Cari
                      </b-button>
                      <b-button
                        variant="danger"
                        squared
                        size="sm"
                        @click="reset"
                      >
                        Reset
                      </b-button>
                    </template>
                    <b-form-input
                      type="text"
                      v-model="filter.name"
                      placeholder="Cari Nama"
                    ></b-form-input>
                  </b-input-group>
                </div>
              </div>
              <b-table
                striped
                hover
                :items="items"
                :fields="fields"
                class="mt-3"
                responsive
              >
                <template #table-colgroup="scope">
                  <col
                    v-for="field in scope.fields"
                    :key="field.key"
                    :style="{ width: field.key === 'action' ? '25%' : '' }"
                  />
                </template>

                <template #cell(action)="data">
                  <b-button
                    size="sm"
                    class="mr-1 btn-circle btn-outline-success"
                    v-b-tooltip.hover
                    title="Edit"
                    @click="
                      $router.push({
                        path: '/masters/uom/edit/' + data.item.id,
                      })
                    "
                    v-if="btnAccess"
                    ><i class="fas fa-edit px-0"></i
                  ></b-button>
                  <b-button
                    size="sm"
                    class="btn-circle btn-outline-danger mr-1"
                    v-b-tooltip.hover
                    title="Hapus"
                    @click="deleteData(data.item.id)"
                    v-if="btnAccess"
                    ><i class="fas fa-trash px-0"></i
                  ></b-button>
                </template>
              </b-table>

              <b-pagination
                v-if="items.length != 0"
                v-model="currentPage"
                :total-rows="totalRows"
                :per-page="perPage"
                @page-click="pageOnClick"
                class="mt-4"
              ></b-pagination>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import module from "@/core/modules/CrudModule.js";

export default {
  data() {
    return {
      // filter
      filter: {
        name: "",
      },
      // Pagination
      perPage: 20,
      currentPage: 1,
      totalRows: 1,
      fields: [
        {
          key: "number",
          label: "No",
          sortable: true,
        },
        {
          key: "name",
          label: "Nama",
          sortable: true,
        },
        {
          key: "action",
          label: "Aksi",
          tdClass: "nowrap",
        },
      ],
      items: [],
      // access
      btnAccess: true,
    };
  },
  methods: {
    pageOnClick(evt, page) {
      evt.preventDefault();
      //("evt", evt);
      //("page", page);
      this.currentPage = page;
      this.pagination();
    },

    async pagination() {
      let filterParams = `&name=${this.filter.name}`;
      let response = await module.paginate(
        "api/uom",
        `?page=${this.currentPage}&page_size=${this.perPage}${filterParams}`
      );
      let pagination = response.meta.pagination;
      this.totalRows = pagination.total;

      let a, b;
      b = this.perPage * (this.currentPage - 1) + 1;
      for (a = 0; a < response.data.length; a++) {
        response.data[a].number = b + a;
      }

      this.items = response.data;
    },

    search() {
      this.pagination();
    },
    reset() {
      this.filter.name = "";
      this.pagination();
    },

    async deleteData(id) {
      // Delete Data
      let result = await module.delete("api/uom/" + id);
      // If Deleted
      if (result) {
        this.pagination();
      }
    },

    // access
    checkAccess() {
      let access_right_user = window.localStorage.getItem(
        "access_right_display"
      );
      let access_right = JSON.parse(access_right_user);
      for (let a = 0; a < access_right.length; a++) {
        if (access_right[a] == "9016") {
          this.btnAccess = true;
        }
      }
    },
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Satuan", route: "/masters/uom" },
    ]);
    this.pagination();
    this.checkAccess();
  },
};
</script>

<style>
.nowrap {
  white-space: nowrap;
}
</style>
